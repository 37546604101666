import { http } from "@/utils/http";
import type { Result, PageResult } from "@/api/interface";

export type UserInfo = {
  id: number;
  createTime: string;
  updateTime: string;
  nickname: string;
  username: string;
  password: string;
  email: null | string;
  phone: string;
  avatar: null | string;
  isDeleted: number;
  pid: number;
  /** 0 普通用户 1 管理员 */
  identity: number;
  remainingTime: number;
  roleId: number;
  timbrePlace: number;
  money: number;
  companyId: number;
  language: null; // ???
  createdBy: number;
  canUseCompanyTime: boolean;
  canUseMultilingual: boolean;
  role: {
    id: number;
    createTime: null | string;
    updateTime: null | string;
    level: number;
    roleName: string; //"普通用户";
    isTextSameScreenTranslation: boolean;
    isBothTranslation: boolean;
    isCustomTranslation: boolean;
    isCustomTimbre: boolean;
    defaultTime: number;
    originPrice: number;
    price: number;
  };
};

export type LoginResult = {
  access_token: string;
  user: UserInfo;
};

export type UserResult = {
  success: boolean;
  data: {
    /** 头像 */
    avatar: string;
    /** 用户名 */
    username: string;
    /** 昵称 */
    nickname: string;
    /** 当前登录用户的角色 */
    roles: Array<string>;
    /** `token` */
    accessToken: string;
    /** 用于调用刷新`accessToken`的接口时所需的`token` */
    refreshToken: string;
    /** `accessToken`的过期时间（格式'xxxx/xx/xx xx:xx:xx'） */
    expires: Date;
  };
};

export type UserListParams = {
  page: number;
  size: number;
  nickname?: string;
  username?: string;
  companyId?: number;
};

export type UpdateUserParams = {
  user_id: number;
  nickname: string;
  email: string;
  phone: string;
  password: string;
  avatar: string;
  canUseCompanyTime: boolean;
  canUseMultilingual: boolean;
};

export type AddUserParams = {
  nickname: string;
  email: string;
  phone: string;
  avatar: string;
  username: string;
  password: string;
  companyId: number;
  identity: number;
};

export interface LoginParams {
  username: string;
  password: string;
}

/** 登录 */
export const getLogin = (data: LoginParams) => {
  return http.request<Result<LoginResult>>("post", "/login", { data });
};

// 查看自己的信息
export const getMyInfo = () => {
  return http.request<Result<UserInfo>>("get", "/user/me");
};

// 查询user列表
export const getUserList = (data: UserListParams) => {
  let url = "/user/list";
  const params = new URLSearchParams();

  if (data.nickname || data.username) {
    if (data.nickname) {
      params.append("nickname", data.nickname);
    }
    if (data.username) {
      params.append("username", data.username);
    }
  }
  if (data.companyId !== null && data.companyId !== undefined) {
    params.append("companyId", data.companyId.toString());
  }
  params.append("page", data.page.toString());
  params.append("size", data.size.toString());
  url += `?${params.toString()}`;
  return http.request<PageResult<UserInfo[]>>("get", url);
};

// 查询单个User信息
export const getTargetUserInfo = (id: number) => {
  return http.request<Result<UserInfo>>("get", `/user/detail/${id}`);
};

// 修改User信息
export const updateUserInfo = (data: UpdateUserParams) => {
  return http.request<Result<UserInfo>>("put", `/user/update/${data.user_id}`, {
    data
  });
};

// 删除User
export const deleteUser = (id: number) => {
  return http.request<Result<UserInfo>>("delete", `/user/delete/${id}`);
};

// 新增User
export const addUser = (data: AddUserParams) => {
  return http.request<Result<UserInfo>>("post", "/user/add", { data });
};
